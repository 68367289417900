import {
  Table,
  Card,
  CardHeader,
  Button,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { findByUserIdForAdmin } from "actions/Students";
import { Link, useParams } from "react-router-dom";
import { getAllActiveGrades } from "actions/Grades";

const ClassStudentDetails = () => {

  const { id } = useParams();
  const [enrolledClasses, setEnrolledClasses] = useState(null);
  const [isEnrolledClasses, setIsEnrolledClasses] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [allGrades, setAllGrades] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(enrolledClasses);
 
  const [isData, setIsData] = useState({
    id: "",
    enrolled_classes: [],
  });

  const fetchData = async () => {
    try {
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
      const enrolledClasses = await findByUserIdForAdmin(id);
      setEnrolledClasses(enrolledClasses);
      setFilteredStudents(null);
      setLoadingStudents(false);
      if (enrolledClasses.length > 0) {
        setIsEnrolledClasses(true);
      }
      setIsData({ ...isData, id: +id });
    } catch (error) {
      setLoadingStudents(false);
      setIsEnrolledClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
  }, [isData]);

  
  const searchCategory = (searchQuery) => {
    setFilteredStudents(
      enrolledClasses.filter((enrolledClass) =>
        enrolledClass.teacher_class.teacher.full_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  };

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isEnrolledClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Entrolled Classes</h3>
                </Col>
                <Col className="justify-content-end" md="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by teacher"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Teacher</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Status</th>
                  <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents
                  ? filteredStudents?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="secondary"
                          type="button"
                          tag={Link}
                          to={`/high-level-admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                      </td>
                    </tr>
                  ))
                  : enrolledClasses?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="primary"
                          type="button"
                          tag={Link}
                          to={`/high-level-admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassStudentDetails;
