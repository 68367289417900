import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useParams } from "react-router-dom";
import { getPaymentsByTeacherId } from "actions/Teachers";
import { downloadPaymentByTeacherIdCsvFile } from "actions/Teachers";
import { getBalanceByTeacherId } from "actions/Teachers";
import { getUser } from "actions/Auth";
import { makeTeacherPayment } from "actions/Teachers";
import { HiDownload } from "react-icons/hi";

const TeacherPaymentDetails = () => {
  const { teacherId, full_name } = useParams();
  const [teacherClassPayments, setTeacherClassPayments] = useState(null);
  const [isTeacherClassPayments, setIsTeacherClassPayments] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [editModal, setEditmodal] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [senderId, setSenderId] = useState(null);

  const fetchData = async () => {
    try {
      const user = await getUser();
      setSenderId(user.id);
      const paymentDetails = await getPaymentsByTeacherId(teacherId);
      const balance = await getBalanceByTeacherId(teacherId);
      setTeacherClassPayments(paymentDetails.data);
      setBalance(balance.payment_balance);
      setLoadingStudents(false);
      setIsTeacherClassPayments(true);
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClassPayments(false);
    }
  };

  const downloadBlob = async () => {
    try {
      await downloadPaymentByTeacherIdCsvFile(teacherId, full_name);
    } catch (error) {
      setFailed(true);
      setErrorMessage(error.message);
    }
  };

  const makeAPayment = async () => {
    if (!amount) {
      setFailed(true);
      setErrorMessage("Value must be greater than 0");
      setAmount(0);
    } else {
      try {
        setDisabledEditButton(true);
        const paymentDetails = {
          amount: amount,
          teacherId: +teacherId,
          senderId: +senderId
        }
        const body = JSON.stringify(paymentDetails);
        const response = await makeTeacherPayment(body);
        if (response.success === true) {
          setSuccessMessage(response.message);
          setSuccess(true);
          fetchData();
          setEditmodal(false);
          setAmount(0);
        } else {
          setFailed(true);
          setErrorMessage(response.message);
          setSuccess(false);
          fetchData();
          setAmount(0);
        }
      } catch (error) {
        setFailed(true);
        setErrorMessage(error.message);
        setSuccess(false);
        fetchData();
        setAmount(0);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        {/* Display Tabel */}
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClassPayments ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="headerSection border-0">
              <div md="6">
                <h3 className="mb-2">Payment Records</h3>
                {balance ?
                  <h3>Payment Balance: Rs.{balance ? balance : null}</h3> :
                  <h3>No payments available yet</h3>}
              </div>
              <div className="paymentSection">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => setEditmodal(true)}
                  style={{ marginRight: "0px" }}
                >
                  Make a Payment
                </Button>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => downloadBlob()}
                  className="exportBtn">
                  <HiDownload /> Export
                </Button>
              </div>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Teacher</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {teacherClassPayments?.map((teacherClassPayment, index) => (
                  <tr key={index}>
                    <td>{teacherClassPayment.created_at.split("T")[0]}</td>
                    <td>
                      {
                        teacherClassPayment.teacher.full_name
                      }
                    </td>
                    <td>
                      {teacherClassPayment.sender.full_name}
                    </td>
                    <td>
                      {teacherClassPayment.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
        {/* Edit teacherClassPayment */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Make a Payment
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setAmount(0)
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      id="amount"
                      placeholder="Amount"
                      type="number"
                      name="amount"
                      value={amount}
                      max={balance}
                      onChange={(e) => {
                        const enteredValue = Math.min(parseFloat(e.target.value), balance);
                        setAmount(enteredValue);
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => makeAPayment()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Pay
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default TeacherPaymentDetails;
