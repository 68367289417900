import {
  Table,
  Card,
  CardHeader,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  Modal,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useParams } from "react-router-dom";

import "../../../assets/css/qr-card-styles.css";
import "../../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { getClassStudentsByLimit } from "actions/Students";
import { getClassStudentsBySearch } from "actions/Students";

const ClassStudentList = () => {
  const { classTeacherId } = useParams();
  const [allStudents, setAllStudents] = useState(null);
  const [isAllStudents, setIsAllStudents] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPge = 10;
  const skip = 0;
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const getStudents = async (itemsPerPge, skip) => {
    const students = await getClassStudentsByLimit(itemsPerPge, skip, classTeacherId);
    setPageCount(students.data.pageCount);
    setAllStudents(students.data.data);
    if (students.data.data.length > 0) {
      setIsAllStudents(true);
    }
  }

  const fetchData = async () => {
    try {
      await getStudents(itemsPerPge, skip);
      setLoadingStudents(false);
    } catch (error) {
      setLoadingStudents(false);
      setIsAllStudents(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchCategory = async (searchQuery) => {
    const searched = await getClassStudentsBySearch(itemsPerPge, skip, searchQuery, classTeacherId);
    setPageCount(searched.data.pageCount);
    setAllStudents(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getClassStudentsBySearch(itemsPerPge, skip, searchQuery, classTeacherId);
    setPageCount(searched.data.pageCount)
    setAllStudents(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllStudents ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Students</h3>
                </Col>
                <Col className="justify-content-end" md="8" lg="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by full name"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                        setCurrentStart(0);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Reg. No</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Contact Number</th>
                </tr>
              </thead>
              <tbody>
                {allStudents?.map((student, index) => (
                  <tr key={index}>
                    <td>{student.user.registration_number}</td>
                    <td>{student.user.full_name}</td>
                    <td>{student.user.barcode}</td>
                    <td>{student.user.phone_number}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div >
    </>
  );
};

export default ClassStudentList;
