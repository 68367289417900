import {
    Card,
    Modal,
    Spinner,
    Button,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import "../../assets/css/file-upload-styles.css";
import * as XLSX from 'xlsx';
import { bulkStudentRegister } from "actions/Students";

const StudentBulkRegistration = () => {

    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileContent, setFileContent] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);

    const fetchData = async () => {
        try {

        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const expectedHeaders = ["full_name", "phone_number", "index", "address", "g_name", "land_phone_number"];

    const validateHeaders = (headers) => {
        const details = expectedHeaders.every(header => headers.includes(header));
        return details
    };

    const readFileContent = async (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result;
            let headers;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            headers = worksheet[0];
            if (validateHeaders(headers)) {
                setErrorMessage("");
                // Create a mapping of headers to column indices
                const headerMapping = {};
                headers.forEach((header, index) => {
                    headerMapping[header] = index;
                });

                // Convert DOB (and any other date fields) from serial number to date
                const formattedContent = worksheet.slice(1).map(row => {
                    if (row[headerMapping['dob']] && !isNaN(row[headerMapping['dob']])) {
                        row[headerMapping['dob']] = XLSX.SSF.format("yyyy-mm-dd", row[headerMapping['dob']]); // Convert to date string
                    }
                    return row;
                });

                setFileContent([headers, ...formattedContent]);
                setUploadProgress(0);
            } else {
                setSelectedFile(null);
                setUploadProgress(0);
                setErrorMessage("Invalid file headers. Expected headers: " + expectedHeaders.join(", "));
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        readFileContent(file);
    };

    const dateToString = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            // You can choose the format. Here are two common formats:
            return date.toISOString(); // Example: "2024-10-16T14:22:05.123Z"
            // return date.toLocaleDateString("en-GB"); // Example: "16/10/2024"
        }
        return date; // Return the original value if it's not a valid date
    };

    const prepareStudentDataForApi = (students) => {
        return students.map(student => {
            // Convert 'dob' to string format if it's a valid date
            if (student.dob) {
                student.dob = dateToString(new Date(student.dob));
            }
            // Similarly, convert other date fields if necessary
            // student.some_other_date = dateToString(new Date(student.some_other_date));

            return student;
        });
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            setUploadStatus("Please select a file to upload.");
            return;
        }
        const headers = fileContent[0];
        const rows = fileContent.slice(1);

        const headerMapping = {};
        headers.forEach((header, index) => {
            headerMapping[header] = index;
        });

        // Convert the dob column to string (if it exists)
        const formattedRows = rows.map((row) => {
            if (row[headerMapping["dob"]] && !isNaN(row[headerMapping["dob"]])) {
                row[headerMapping["dob"]] = XLSX.SSF.format("yyyy-mm-dd", row[headerMapping["dob"]]);  // Convert to string
            }
            return row;
        });

        // Create a new sheet with the formatted rows and headers
        const newSheetData = [headers, ...formattedRows];
        const newWorksheet = XLSX.utils.aoa_to_sheet(newSheetData);  // Convert array to worksheet
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Sheet1");  // Append the new sheet to the workbook

        // Convert the workbook to a Blob (binary large object)
        const workbookBinary = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([workbookBinary], { type: "application/octet-stream" });

        // Create a FormData object
        const formData = new FormData();
        formData.append('file', blob, selectedFile.name);
        setUploading(true);
        try {
            // const formData = new FormData();
            // formData.append('file', selectedFile);
            const response = await bulkStudentRegister(formData);
            if (response.statusCode === 200) {
                setSuccess(true);
                setSuccessMessage(response.message)
                setSelectedFile(null);
                setFileContent(null);
                setUploading(false);
            }
            else {
                setFailed(true);
                setErrorMessage(response.message);
                setUploading(false);
            }
        } catch (error) {
            setErrorMessage(error.response.message);
            setFailed(true);
            setUploading(false);
            setSelectedFile(null);
            setFileContent(null);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add('highlight');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove('highlight');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const droppedFile = files[0];
            setSelectedFile(droppedFile);
            setErrorMessage("");
            readFileContent(droppedFile);
        }
        e.currentTarget.classList.remove('highlight');
    };

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 shadow">
                    <div className="uploadBoxContainer">
                        <div className="uploadBox"
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className="uploadIcon">
                                <i className="fas fa-cloud-upload-alt"></i>
                            </div>
                            <h3 className="uploadTitle">Upload File</h3>
                            <p className="instructions">
                                Drop XLSX/XLS file to upload or{' '}
                                <span className="browse" onClick={() => document.getElementById('fileInput').click()}>
                                    Browse
                                </span>
                            </p>
                            <p className="instructions">Expected headers: full_name, phone_number, index, address, g_name, land_phone_number add double quote for each word</p>
                            <input
                                id="fileInput"
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileChange}
                                className="uploadInput"
                            />
                            {uploadStatus && <p className="status">{uploadStatus}</p>}
                            {uploadProgress > 0 && (
                                <div className="progress">
                                    <div
                                        className="progressBar"
                                        style={{ width: `${uploadProgress}%` }}
                                    ></div>
                                </div>
                            )}
                            {isErrorMessage && <p className="error">{isErrorMessage}</p>}
                        </div>
                        <div>
                            {fileContent && !isErrorMessage && (
                                <div className="file-content">
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            {fileContent.slice(0, 6).map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {row.map((cell, cellIndex) => (
                                                        <td key={cellIndex}>{cell}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                            {fileContent.length > 6 && (
                                                <tr>
                                                    <td colSpan="5">... and {fileContent.length - 6} more rows</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {!isUploading ? <Button
                                        color="primary"
                                        type="button" onClick={handleFileUpload}>Start Bulk Registration</Button>
                                        : <Button
                                            color="primary"
                                            type="button"><Spinner size="sm"></Spinner></Button>}
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default StudentBulkRegistration;
