import {
    Card,
    CardHeader,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Row,
    Col,
    Button,
} from "reactstrap";
import { useState, useEffect } from "react";
import { getAllActiveGrades } from "actions/Grades";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { getActiveStudentsByClassAssistants } from "actions/ClassAssistantLayout";
import Paginations from "components/Pagination/Paginations";
import QRCode from 'react-qr-code';
import Barcode from "react-barcode";
import { usePDF, Margin } from 'react-to-pdf';
import '../../assets/css/barcode-style.css';

const StudentIdCreate = () => {
    const [allGrades, setAllGrades] = useState(null);
    const [gradeFilterDropdownOpen, setGradeFilterDropdownOpen] = useState(false);
    const [selectedGradeFilter, setSelectedGradeFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPge = 100;
    const skip = 0;
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const [groupedBarcodes, setGroupedBarcodes] = useState([]);

    const fetchData = async () => {
        try {
            const grades = await getAllActiveGrades();
            setAllGrades(grades);
        } catch (error) {
            setAllGrades(null);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getStudentsByGrade = async (grade, skip, limit) => {
        try {
            const response = await getActiveStudentsByClassAssistants(grade, skip, limit);
            setPageCount(response.pageCount);

            const newGroupedBarcodes = [];
            for (let i = 0; i < response.data.length; i += 2) {
              newGroupedBarcodes.push([...response.data.slice(i, i + 2)]);
            }
            setGroupedBarcodes(newGroupedBarcodes);
        } catch (error) {

        }
    }

    const handlePagination = async (pageNumber) => {
        await getStudentsByGrade(selectedGradeFilter.name, pageNumber - 1, itemsPerPge);
        setCurrentPage(pageNumber);
    }

    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: "usepdf-example.pdf",
        page: { margin:{
            top: Margin.NONE,
            right: Margin.NONE,
            bottom: 5,
            right: Margin.NONE
        } },
      });

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="shadow" style={{ padding: "1rem" }}>
                    <CardHeader className="border-0">
                                <h3 className="mb-0">Students</h3>
                    </CardHeader>
                    <Row>       
                    <Col md="8">
                    <FormGroup>
                        <Dropdown
                            isOpen={gradeFilterDropdownOpen}
                            toggle={() => setGradeFilterDropdownOpen(!gradeFilterDropdownOpen)}
                        >
                            <DropdownToggle caret>
                                {selectedGradeFilter ? selectedGradeFilter.name : "Grade"}
                            </DropdownToggle>
                            <DropdownMenu
                                style={{ maxHeight: 250, overflowY: "auto" }}
                            >
                                {allGrades?.map((grade) => (
                                    <DropdownItem
                                        key={grade.id}
                                        value={grade.id}
                                        onClick={() => [
                                            setSelectedGradeFilter(grade),
                                            getStudentsByGrade(grade.name, skip, itemsPerPge)
                                        ]}
                                    >
                                        {grade.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </FormGroup>
                    </Col>
                    <Col md="4">
                                <FormGroup>
                                    <Button type="button" color="primary" onClick={toPDF}>Generate</Button>
                                </FormGroup>
                            </Col>
                            </Row>
                    <div style={{ paddingTop: "10px" }}>
                        {pageCount > 1 ?
                            <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                currentStart={currentStart}
                                setCurrentStart={setCurrentStart}></Paginations>
                            : null}
                    </div>
                    <div className="receipt" ref={targetRef}>
            {groupedBarcodes.map((group, index) => (
                <div key={index} className="receiptPaper" style={{ breakInside: 'avoid' }}>
                    {group.map((item, idx) => (
                        <div key={idx} className="">
                            <div className='barcodeCard'>
                                <div className='qrRow'>
                                    <div className='studentDetails'>                                      
                                    <div>
                                        <h3 className="New Leeds">New Leeds Institute</h3>
                                    </div>
                                        <h5 className="stDetails">Grade: {item.grade}</h5>
                                        <h5 className="stDetails">Name: {item.full_name}</h5>
                                    </div>
                                    <div>
                                        <QRCode value={item.barcode} size={113} className='qrCode'/>
                                    </div>
                                </div>
                                <div className="barcodeRow">
                                    <div>
                                        <Barcode value={item.barcode} width={3} height={80} className='barCode'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
                </Card>
            </div>
        </>
    )
}

export default StudentIdCreate;