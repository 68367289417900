import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { getUser } from "actions/Auth";
import { useNavigate } from "react-router-dom";
import Loader from "views/pages/Loader";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
        setUserDetails(true);
        setUserLoading(false);
      } catch (error) {
        setUserDetails(true);
        setUserLoading(false);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (userLoading === true) {
          <Loader></Loader>;
        } else if (userLoading === false) {
          if (user && userDetails) {
            if (user.roleName === "teacher") {
              return navigate("/teacher/dashboard");
            } 
            else if(user.roleName === 'high-level-admin') {
              navigate("/high-level-admin/index");
            }
            else if(user.roleName === 'card-marker') {
              navigate("/classassistant/student");
            }
            else if (user.roleName === "admin") {
              return (
                <Route
                  path={prop.path}
                  element={prop.component}
                  key={key}
                  exact
                />
              );
            }
          } else if (!user && userDetails) {
            return navigate("/auth/login");
          }
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        location?.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/New Leeds-logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText(location?.pathname)} />
        <Routes>
          {getRoutes(routes)}
          <Route path="*"/>
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
