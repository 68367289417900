import { getTeacherPaymentsByClassAssistants } from 'actions/ClassAssistantLayout';
import { getAllTeachers } from 'actions/Teachers';
import TeacherPaymentReceipt from 'components/Reports/TeacherPaymentReceipt';
import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
    Card, CardHeader, Form, Row, Col, FormGroup, Input, Button, Table,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody,
} from "reactstrap";
import '../../../assets/css/class-assistant-payments.css';
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SecondaryHeader from 'components/Headers/SecondaryHeader';
import { getUser } from 'actions/Auth';

const TeacherDailyReport = () => {
    const componentRef = useRef();
    const monthlyRef = useRef();
    const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(null);
    const [allTeachers, setAllTeachers] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [months, setMonths] = useState([]);
    const [user, setUser] = useState(null);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const freshMonth = year + "-" + month;
    const freshDate = year + "-" + month + "-" + day;
    const [selectedMonth, setSelectedMonth] = useState(freshMonth);
    const [selectedDate, setSelectedDate] = useState(freshDate);
    const [isMonth, setMonth] = useState(false);
    const [isDate, setDate] = useState(false);
    const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);

    const getMonthList = async () => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - (12 - 1));
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const months = [];

        for (let year = startYear; year <= endYear; year++) {
            const monthStart = year === startYear ? startMonth : 0;
            const monthEnd = year === endYear ? endMonth : 11;

            for (let month = monthStart; month <= monthEnd; month++) {
                const monthString = `${year}-${(month + 1)
                    .toString()
                    .padStart(2, "0")}`;
                months.push({
                    label: monthString,
                    month: month + 1,
                    year: year,
                });
            }
        }
        setMonths(months);
    }

    const getTeachers = async () => {
        try {
            const response = await getUser();
            setUser(response);
            const paymentResponse = await getTeacherPaymentsByClassAssistants(user.id, freshDate);
            if (paymentResponse.data.length > 0) {
                setPaymentDetails(paymentResponse.data);
            } else {
                setPaymentDetails(null);
            }
            setSelectedDate(null);
            setSelectedMonth(null);
        } catch (error) {
            setAllTeachers(null);
        }
    }

    const fetchData = async () => {
        try {
            await getMonthList();
            await getTeachers();
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterBySearch = async (label) => {
        setMonth(true);
        setDate(false);
        setSelectedDate(null);
        setSelectedMonth(label);
            const response = await getTeacherPaymentsByClassAssistants(user.id, label);
            if (response.data.length > 0) {
                setPaymentDetails(response.data);
            } else {
                setPaymentDetails(null);
            }
    }

    const filterByDate = async (date) => {
        try {
            setDate(true);
            setMonth(false);
            setSelectedMonth(null);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const newDate = year + "-" + month + "-" + day;
            setSelectedDate(newDate);
                const response = await getTeacherPaymentsByClassAssistants(user.id, newDate);
                if (response.data.length > 0) {
                    setPaymentDetails(response.data);
                } else {
                    setPaymentDetails(null);
                }
        } catch (error) {
            setPaymentDetails(null);
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @page {
        width: 80mm; 
        margin-top: 0cm; 
        margin-right:0mm;
        margin-bottom: 0cm;
        margin-left: 0cm;
        }
        `,
    });

    const [selectedPayment, setSelectedPayment] = useState(null);

    useEffect(() => {
        if (selectedPayment) {
            handlePrint();
        }
    }, [selectedPayment]);

    const openMonthlyRep = () => {
        const doc = new jsPDF({
            orientation: 'landscape', // Landscape mode
            unit: 'pt', // Points, for A4 sizing
            format: 'a4'
        });

        // Title for the PDF
        doc.setFontSize(18);
        doc.text('Monthly Payment Details', 40, 40);
        doc.setFontSize(16);
        doc.text(`${user.full_name}`, 40, 80);

        // Example table columns
        const columns = [
            { header: 'Class', dataKey: 'class' },
            { header: 'Paid Normal', dataKey: 'full_count' },
            { header: '80% Card', dataKey: 'half_free_count' },
            { header: 'Free Card', dataKey: 'full_free_count' },
            { header: 'Previous', dataKey: 'prev_count' },
            { header: 'Rate', dataKey: 'teacher_percentage' },
            { header: 'Total', dataKey: 'total' },
            { header: 'Settlement', dataKey: 'settle_total' },
        ];

        // Example table rows
        const rows = paymentDetails.map(payment => ({
            class: `${payment.subject} - ${payment.grade}`,
            full_count: payment.full_count,
            half_free_count: payment.half_free_count,
            full_free_count: payment.full_free_count,
            prev_count: payment.prev_count,
            teacher_percentage: payment.teacher_percentage,
            total: parseFloat(payment.total).toFixed(2),
            settle_total: parseFloat(payment.settle_total).toFixed(2),
        }));

        // Generate the table in the PDF
        doc.autoTable({
            startY: 100, // Where to start the table in the PDF
            head: [columns.map(col => col.header)],
            body: rows.map(row => columns.map(col => row[col.dataKey])),
            styles: {
                fontSize: 10, // Font size for table content,
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineColor: [0, 0, 0],
                lineWidth: 1
            },
        });

        // Open the PDF in a new window
        // doc.save('monthly_payment_details.pdf');
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    }
    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Card className="shadow">
                    <CardHeader className="border-0 assistantStudentHeader">
                        <div className="headerLeft">
                            <h3 className="mb-0">Teacher Payments</h3>
                        </div>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row className="studentInsertUpper">
                            <Col md="12">
                                <Row>
                                    <Col sm="3">
                                        <FormGroup className="mb-2">
                                            <Dropdown
                                                isOpen={monthDropdownOpen}
                                                toggle={() =>
                                                    setMonthDropdownOpen(!monthDropdownOpen)
                                                }
                                                className="filterButton"
                                            >
                                                <DropdownToggle caret>
                                                    {selectedMonth ? selectedMonth : "Filter by Month"}
                                                </DropdownToggle>
                                                {months && months.length > 0 ? (
                                                    <DropdownMenu>
                                                        {months.map((month) => (
                                                            <DropdownItem
                                                                key={month.label}
                                                                value={month.label}
                                                                onClick={() =>
                                                                    filterBySearch(
                                                                        month.label
                                                                    )
                                                                }
                                                            >
                                                                {month.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                ) : null}
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-2">
                                            <DatePicker
                                                showIcon
                                                selected={selectedDate}
                                                onChange={(date) => filterByDate(date)}
                                                className="dateSelector"
                                                icon="fa fa-calendar"
                                                placeholderText="Filter by Date"
                                                fixedHeight="47px"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <Table className="align-items-center" responsive ref={monthlyRef} style={{ display: paymentDetails !== null ? '' : 'none' }}>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Class</th>
                                <th scope="col">Paid Normal</th>
                                <th scope="col">80% Card</th>
                                <th scope="col">Free Card</th>
                                <th scope="col">Previous</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Total</th>
                                <th scope="col">Sttlement</th>
                                <th scope="col">Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentDetails?.map((payment, index) => (
                                <tr key={index}>
                                    <td>{payment.subject} - {payment.grade}</td>
                                    <td>{payment.full_count}</td>
                                    <td>{payment.half_free_count}</td>
                                    <td>{payment.full_free_count}</td>
                                    <td>{payment.prev_count}</td>
                                    <td>{payment.teacher_percentage}</td>
                                    <td>{parseFloat(payment.total).toFixed(2)}</td>
                                    <td>{parseFloat(payment.settle_total).toFixed(2)}</td>
                                    <td>
                                        <Button
                                            color="default"
                                            type="button"
                                            onClick={() => setSelectedPayment(payment)}
                                        >
                                            Print
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className='mt-6 mr-4' style={{ display: isMonth === true && paymentDetails !== null ? 'flex' : 'none', flexDirection: 'row-reverse' }}>
                        <Button
                            color="default"
                            type="button"
                            style={{ width: "400px" }}
                            onClick={openMonthlyRep}
                        >
                            Monthly Report
                        </Button>
                    </div>
                    <Row>
                        <Col></Col>
                        <Col>
                            <div style={{ display: 'none', width: '100%', height: '100%' }}>
                                <TeacherPaymentReceipt ref={componentRef} allDetails={[selectedPayment, selectedTeacherFilter?.full_name, isMonth ? selectedMonth : selectedDate]} />
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                </Card>
            </div>
        </>
    );
}

export default TeacherDailyReport;