import {
  Table,
  Card,
  CardHeader,
  Button,
  Row,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { Link, useParams } from "react-router-dom";
import { findClassesByTeacherIdForAdmin } from "actions/Teachers";

const ClassTeacherDetails = () => {
  const { id } = useParams();
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [isTeacherClasses, setIsTeacherClasses] = useState(false);

  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [todayDate, setTodayDate] = useState(null);

  const fetchData = async () => {
    try {
      const teacherClasses = await findClassesByTeacherIdForAdmin(id);
      setTeacherClasses(teacherClasses);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; 
      const day = currentDate.getDate();
      setTodayDate({
        year: year,
        month: month.toString().padStart(2, "0"),
        day: day.toString().padStart(2, "0"),
      });
      currentDate.setMonth(currentDate.getMonth() - 1);
      setLoadingStudents(false);
      if (teacherClasses.length > 0) {
        setIsTeacherClasses(true);
      }
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isTeacherClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" alt="No data"/>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="headerSection">
                <div className="teacherClassTitle">
                  <h3 className="mb-0">Classes of {teacherClasses[0]?.teacher?.full_name}</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Class Fee</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment Type</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teacherClasses?.map((teacherClass, index) => (
                  <tr key={index}>
                    <td>{teacherClass.grade.name}</td>
                    <td>{teacherClass.subject.name}</td>
                    <td>{teacherClass.price}</td>
                    <td>{teacherClass.status ? "Active" : "Inactive"}</td>
                    <td>{teacherClass.payment_type}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="secondary"
                        type="button"
                        tag={Link}
                        to={`/high-level-admin/teachers/classStudents/${teacherClass.id}`}
                      >
                        Students
                      </Button>
                      {teacherClass.payment_type === "MONTHLY"?
                       <Button
                       color="secondary"
                       type="button"
                       tag={Link}
                       to={`/high-level-admin/teachers/payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}`}
                     >
                       Payment Records
                     </Button>:
                      <Button
                      color="secondary"
                      type="button"
                      tag={Link}
                      to={`/high-level-admin/teachers/daily-payment-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                    >
                      Payment Records
                    </Button>
                      }
                      <Button
                        color="secondary"
                        type="button"
                        tag={Link}
                        to={`/high-level-admin/teachers/attendance-records/${teacherClass.id}/${todayDate.year}-${todayDate.month}-${todayDate.day}`}
                      >
                        Attendance
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default ClassTeacherDetails;
