import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { findByUserIdForAdmin } from "actions/Students";
import { Link, useParams } from "react-router-dom";
import { editStudentClass } from "actions/Students";
import { getAllActiveGrades } from "actions/Grades";
import { findTeachersByGradeId } from "actions/Teachers";
import { findAllSubjectsByTeacherIdAndGradeId } from "actions/Subjects";
import { enrollStudent } from "actions/Students";

const ClassStudentDetails = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const { id } = useParams();
  const [enrolledClasses, setEnrolledClasses] = useState(null);
  const [isEnrolledClasses, setIsEnrolledClasses] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [allGrades, setAllGrades] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
  const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [allSubjects, setAllSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [enrollClsError, setEnrollClsError] = useState('');

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(enrolledClasses);
  const [initialStudent, setInitialStudent] = useState({
    id: null,
    registration_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
    status: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
  });


  const [isData, setIsData] = useState({
    id: "",
    enrolled_classes: [],
  });

  const fetchData = async () => {
    try {
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
      const enrolledClasses = await findByUserIdForAdmin(id);
      setEnrolledClasses(enrolledClasses);
      setFilteredStudents(null);
      setLoadingStudents(false);
      if (enrolledClasses.length > 0) {
        setIsEnrolledClasses(true);
      }
      setIsData({ ...isData, id: +id });
    } catch (error) {
      setLoadingStudents(false);
      setIsEnrolledClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTeachers = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachersByGrade(teachersByGrade);
  }

  const getSubjects = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllSubjects(subjectsByGradeAndTeacher);
  }

  const [selectedClasses, setSelectedClasses] = useState([]);

  const updateSelectedClasses = (grade, teacher, subject) => {
    setSelectedClasses(prevSelectedClasses => [
      ...prevSelectedClasses,
      { grade: grade, teacher: teacher, subject: subject }
    ]);
  };

  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addClass = async () => {
    if (selectedTeacher === null || selectedGradeForStudent === null || selectedSubject === null) {
      setEnrollClsError("Grade, Teacher, and Subject must be selected.")
    } else {
      const newEnrolledClass = {
        teacherId: selectedTeacher.id,
        gradeId: selectedGradeForStudent.id,
        subjectId: selectedSubject.id
      };

      setIsData(prevState => {
        const isAlreadyEnrolled = prevState.enrolled_classes.some(
          enrolledClass =>
            enrolledClass.teacherId === newEnrolledClass.teacherId &&
            enrolledClass.gradeId === newEnrolledClass.gradeId &&
            enrolledClass.subjectId === newEnrolledClass.subjectId
        );

        if (isAlreadyEnrolled) {
          setFailed(true);
          setErrorMessage('This student is already enrolled in this class.');
          return prevState; 
        }

        const updatedEnrolledClasses = [...prevState.enrolled_classes, newEnrolledClass];
        return {
          ...prevState,
          enrolled_classes: updatedEnrolledClasses
        };
      });

      setSelectedClasses(prevSelectedClasses => {
        const isAlreadySelected = prevSelectedClasses.some(
          selectedClass =>
            selectedClass.grade === selectedGradeForStudent.name &&
            selectedClass.teacher === selectedTeacher.full_name &&
            selectedClass.subject === selectedSubject.name
        );
        if (!isAlreadySelected) {
          return [
            ...prevSelectedClasses,
            { grade: selectedGradeForStudent.name, teacher: selectedTeacher.full_name, subject: selectedSubject.name }
          ];
        }
        return prevSelectedClasses;
      });
    }
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setIsData(prevState => {
      const updatedEnrolledClasses = prevState.enrolled_classes.slice();
      updatedEnrolledClasses.splice(enrolledClassIdToRemove, 1);
      return {
        ...prevState,
        enrolled_classes: updatedEnrolledClasses
      };
    });
    removeSelectedClass(enrolledClassIdToRemove);

  };
  useEffect(() => {
  }, [isData]);

  const insertNewEnrollment = async () => {
    setinsertButtonDisabled(true);
    const response = await enrollStudent(isData);
    if (response.success === true) {
      fetchData();
      setSuccessMessage("Succesfully Enrolled the Student!");
      setSuccess(true);
      setSelectedGradeForStudent(null);
      setSelectedTeacher(null);
      setSelectedSubject(null);
      setSelectedClasses([]);
    } else {
      setErrorMessage(response.message);
      setFailed(true);
    }
  };

  const searchCategory = (searchQuery) => {
    setFilteredStudents(
      enrolledClasses.filter((enrolledClass) =>
        enrolledClass.teacher_class.teacher.full_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  };

  const handleEditClick = (enrolledClass) => {
    setSelectedStudent(enrolledClass);
    setInitialStudent(enrolledClass);
    setEditmodal(true);
  };

  const editStudentClassDetails = async () => {
    const response = await editStudentClass(selectedStudent);
    if (response.success === true) {
      setSuccessMessage(response.message);
      setSuccess(true);
      fetchData();
      setEditmodal(false);
    } else {
      setErrorMessage(response.message);
      setFailed(true);
    }
  }

  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Enroll Student</h3>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row style={{ justifyContent: "space-between" }}>
              <Col md="12">
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md="12" lg="2">
                    <FormGroup>
                      <Dropdown
                        isOpen={gradeDropdownOpen}
                        toggle={() =>
                          setGradeDropdownOpen(!gradeDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedGradeForStudent ? selectedGradeForStudent.name : "Grade"}
                        </DropdownToggle>
                        {allGrades && allGrades.length > 0 ? (
                          <DropdownMenu className="dropdownMenuStyle">
                            {allGrades && allGrades.map((grade) => (
                              <DropdownItem
                                key={grade.name}
                                value={grade.id}
                                onClick={() => [
                                  setSelectedTeacher(null),
                                  setSelectedSubject(null),
                                  setSelectedGradeForStudent(grade),
                                  getTeachers(grade.id)
                                ]}
                              >
                                {grade.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="4">
                    <FormGroup>
                      <Dropdown
                        isOpen={teacherDropdownOpen}
                        toggle={() =>
                          setTeacherDropdownOpen(!teacherDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedTeacher ? selectedTeacher.full_name : "Teacher"}
                        </DropdownToggle>
                        {allTeachersByGrade && allTeachersByGrade.length > 0 ? (
                          <DropdownMenu>
                            {allTeachersByGrade && allTeachersByGrade.map((teacher) => (
                              <DropdownItem
                                key={teacher.full_name}
                                value={teacher.id}
                                onClick={() => {
                                  setSelectedTeacher(teacher);
                                  setSelectedSubject(null);
                                  getSubjects(selectedGradeForStudent.id, teacher.id);
                                  setTeacherDropdownOpen(false);
                                }}
                              >
                                {teacher.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="4">
                    <FormGroup>
                      <Dropdown
                        isOpen={subjectDropdownOpen}
                        toggle={() =>
                          setSubjectDropdownOpen(!subjectDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubject ? selectedSubject.name : "Subject"}
                        </DropdownToggle>
                        {allSubjects && allSubjects.length > 0 ? (
                          <DropdownMenu>
                            {allSubjects && allSubjects.map((subject) => (
                              <DropdownItem
                                key={subject.name}
                                value={subject.id}
                                onClick={() => {
                                  setSelectedSubject(subject);
                                  setSubjectDropdownOpen(false);
                                  setinsertButtonDisabled(false);
                                }}
                              >
                                {subject.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="2">
                    <FormGroup>
                      <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()}>✓</Button>
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <Table className="align-items-center" responsive>
                    <tbody>
                      {selectedClasses?.map((selectedClass, index) => (
                        <tr key={index}>
                          <td>{selectedClass.teacher}</td>
                          <td>{selectedClass.subject} - {selectedClass.grade}  </td>
                          <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>X</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "15px" }}>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  insertNewEnrollment();
                }}
                disabled={
                  isInsertButtonDisabled
                }
              >
                Insert
              </Button>
            </Row>
          </Form>
        </Card>
        {/* Display Tabel */}
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isEnrolledClasses ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Entrolled Classes</h3>
                </Col>
                <Col className="justify-content-end" md="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by teacher"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Teacher</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Status</th>
                  <th scope="col-2" style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents
                  ? filteredStudents?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="default"
                          type="button"
                          tag={Link}
                          to={`/admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => handleEditClick(enrolledClass)}
                        >
                          Change Status
                        </Button>
                      </td>
                    </tr>
                  ))
                  : enrolledClasses?.map((enrolledClass, index) => (
                    <tr key={index}>
                      <td>{enrolledClass.teacher_class.teacher.full_name}</td>
                      <td>{enrolledClass.teacher_class.grade.name}</td>
                      <td>{enrolledClass.teacher_class.subject.name}</td>
                      <td>{enrolledClass.status ? "Active" : "Inactive"}</td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          color="default"
                          type="button"
                          tag={Link}
                          to={`/admin/students/payment-records/${enrolledClass.id}`}
                        >
                          Payment Records
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => handleEditClick(enrolledClass)}
                        >
                          Change Status
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        )}
        {/* Edit enrolledClass */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Change Status
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setEditError({
                  full_name: "",
                  phone_number: "",
                  password: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}>
                      <DropdownToggle caret>
                        {selectedStudent
                          ? selectedStudent.status
                            ? "Active"
                            : "Inactive"
                          : "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedStudent({
                              id: selectedStudent.id,
                              status: true,
                            });
                            setEditedInput(true);
                            setDisabledEditButton(false);
                          }}
                        >
                          Active
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => {
                            setSelectedStudent({
                              id: selectedStudent.id,
                              status: false,
                            });
                            setEditedInput(true);
                            setDisabledEditButton(false);
                          }}
                        >
                          Inactive
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editStudentClassDetails()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ClassStudentDetails;
