import {
    Table,
    Card,
    CardHeader,
    Input,
    Row,
    Col,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useParams } from "react-router-dom";
import "../../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { findFreeCards } from "actions/Teachers";

const TeacherClassFreeCard = () => {
    const { teacherId, gradeId, subjectId } = useParams();
    const [allStudents, setAllStudents] = useState(null);
    const [isAllStudents, setIsAllStudents] = useState(false);
    const [isLoadingStudents, setLoadingStudents] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPge = 10;
    const skip = 0;
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString();
    const freshMonth = year + "-" + month;
    const [selectedMonth, setSelectedMonth] = useState(freshMonth);
    const [newMonth, setNewMoth] = useState(month);
    const [selectedYear, setSelectedYear] = useState(year);
    const [months, setMonths] = useState([]);

    const getMonthList = async () => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - (12 - 1));
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const months = [];

        for (let year = startYear; year <= endYear; year++) {
            const monthStart = year === startYear ? startMonth : 0;
            const monthEnd = year === endYear ? endMonth : 11;

            for (let month = monthStart; month <= monthEnd; month++) {
                const monthString = `${year}-${(month + 1)
                    .toString()}`;
                months.push({
                    label: monthString,
                    month: month + 1,
                    year: year,
                });
            }
        }
        setMonths(months);
    }

    const getStudents = async (itemsPerPge, skip) => {
        const students = await findFreeCards(teacherId, gradeId, subjectId, month, year, skip, itemsPerPge);
        setPageCount(students.data.pageCount);
        setAllStudents(students.data);
        if (students.data.data.length > 0) {
            setIsAllStudents(true);
        }
    }

    const fetchData = async () => {
        try {
            await getMonthList();
            await getStudents(itemsPerPge, skip);
            setLoadingStudents(false);
        } catch (error) {
            setLoadingStudents(false);
            setIsAllStudents(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterBySearch = async (label) => {
        setSelectedMonth(label);
        const [year, month] = label.split('-');
        const yearInt = parseInt(year, 10);
        const monthInt = parseInt(month, 10);
        setSelectedYear(yearInt);
        setNewMoth(monthInt);
        const searched = await findFreeCards(teacherId, gradeId, subjectId, monthInt, yearInt, skip, itemsPerPge);
        setPageCount(searched.data.pageCount)
        setAllStudents(searched.data);
        setCurrentPage(skip + 1);
    }

    const handlePagination = async (pageNumber) => {
            const searched = await findFreeCards(teacherId, gradeId, subjectId, newMonth, selectedYear, pageNumber - 1, itemsPerPge);
            setPageCount(searched.data.pageCount)
            setAllStudents(searched.data);
            setCurrentPage(pageNumber);
    }

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                {isLoadingStudents ? (
                    <Card style={{}}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : (
                    <Card className="shadow" style={{ padding: "1rem" }}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col>
                                    <h3 className="mb-0">Free Card Students</h3>
                                </Col>
                                <Col className="justify-content-end" md="8" lg="4">
                                    <Dropdown
                                        isOpen={monthDropdownOpen}
                                        toggle={() =>
                                            setMonthDropdownOpen(!monthDropdownOpen)
                                        }
                                        className="filterButton"
                                    >
                                        <DropdownToggle caret>
                                            {selectedMonth ? selectedMonth : "Filter by Month"}
                                        </DropdownToggle>
                                        {months && months.length > 0 ? (
                                            <DropdownMenu>
                                                {months.map((month) => (
                                                    <DropdownItem
                                                        key={month.label}
                                                        value={month.label}
                                                        onClick={() =>
                                                            filterBySearch(
                                                                month.label
                                                            )
                                                        }
                                                    >
                                                        {month.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        ) : null}
                                    </Dropdown>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Reg. No</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Contact Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allStudents?.map((student, index) => (
                                    <tr key={index}>
                                        <td>{student.class_user.user.registration_number}</td>
                                        <td>{student.class_user.user.full_name}</td>
                                        <td>{student.class_user.user.phone_number}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div style={{ paddingTop: "10px" }}>
                            {pageCount > 1 ?
                                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                    currentStart={currentStart}
                                    setCurrentStart={setCurrentStart}></Paginations>
                                : null}
                        </div>
                    </Card>
                )}
            </div >
        </>
    );
};

export default TeacherClassFreeCard;
