import React from 'react';
import '../../assets/css/receipt-styles.css';
import Barcode from 'react-barcode';

function getMonthName(monthNumber) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Adjust for zero-based indexing if necessary
  const index = monthNumber - 1;

  // Check for valid month number
  if (index >= 0 && index < 12) {
    return monthNames[index];
  } else {
    return "Invalid month number";
  }
}

const StudentBulkPaymentReceipt = React.forwardRef((props, ref) => {
  const invoiceDate = props.allDetails.invoiceDate;
  const date = new Date(props.allDetails.invoiceDate);

const options = { year: 'numeric', month: 'short', day: 'numeric' };
let formattedDate = date.toLocaleDateString('en-US', options);

formattedDate = formattedDate.replace(',', '');
const monthName = getMonthName(props.allDetails.month);

  return (
    <>
    <style>
      {`
      @media print {
          @page {
              size: 80mm 150mm !important;
              margin: 0;
          }
      }
  `}
    </style>
    <div className="payReceipt m-5" ref={ref}>
    <>
  {props.allDetails.payments.map((payment, index) => (
    <div key={index}>
      <div style={{textAlign: 'center', padding: '5px'}}>
        <h3 style={{fontSize: 38, color: 'black'}}>
          {payment.subject} {payment.grade} {payment.year} - {payment.teacherName}</h3>
      </div>
      <div className='studentBulkPayDetails'>
        <h2 style={{fontSize: 36, color: 'black'}}>Invoice No: {payment.invoiceNo}</h2>
        <h2 style={{fontSize: 36, color: 'black'}}>
          Paid Month: {getMonthName(payment.month)} {/* If `monthName` is a function */}
        </h2>
        <h2 style={{fontSize: 36, color: 'black'}}>
          Paid Amount: {Number(payment.paidAmount).toFixed(2)}{" "}
          {payment.payType === 'HALF_FREE' ? `(80% Card)` : 
            payment.payType === 'FULL_FREE' ? `(Free Card)` : null
          }
        </h2>
      </div>
    </div>
  ))}
</>
      <div className='studentReceiptBarcodeSection'>
        <h2 style={{fontSize: 36, marginTop: '10px'}}>{props.allDetails.studentName}</h2>
        <Barcode value={props.allDetails.barcode} width={3} height={60} className='studentReceiptBarCode'/>
      </div>
      <hr style={{height: 2, color: 'black'}}/>
      <div className='studentReceiptInstSection'>
        <h2 style={{fontSize: 25, color: 'black', fontWeight: 'bold'}}>New LEEDS Academy</h2>
        <h2 style={{fontSize: 22, color: 'black', fontWeight: 'bold'}}>76, Hunukotuwa Road</h2>
        <h2 style={{fontSize: 22, color: 'black', fontWeight: 'bold'}}>Badulla</h2>
        <h2 style={{fontSize: 22, color: 'black', fontWeight: 'bold'}}>0728676766</h2>
      </div>
    </div>
    </>
  );
});

export default StudentBulkPaymentReceipt;