import {
  Table,
  Card,
  CardHeader,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { useNavigate, useParams } from "react-router-dom";
import { findAttendanceByClassForAdmin, findAbsentByClassForAdmin } from "actions/Teachers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/styles.css";
import "../../../assets/css/toggle-button-styles.css";
import Paginations from "components/Pagination/Paginations";

const TeacherClassAttendanceSheet = () => {
  const navigate = useNavigate();
  const { teacherClassId, date } = useParams();
  const [teacherClasses, setTeacherClasses] = useState(null);
  const [isTeacherClasses, setIsTeacherClasses] = useState(false);
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPge = 10;
  const skip = 0;
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [startDate, setStartDate] = useState(date);
  const [isChecked, setIsChecked] = useState(true);

  const getAttendance = async (selectedDate, pageNumber, searchQuery) => {
    const teacherClasses = await findAttendanceByClassForAdmin(teacherClassId, selectedDate, pageNumber, itemsPerPge, searchQuery);
    setTeacherClasses(teacherClasses.data);
    setLoadingStudents(false);
    if (teacherClasses.data.length > 0) {
      setIsTeacherClasses(true);
      setPageCount(teacherClasses.pageCount);
      setCurrentPage(pageNumber + 1);
    } else {
      setIsTeacherClasses(false);
      setPageCount(teacherClasses.pageCount);
    }
  }

  const getAbsent = async (selectedDate, pageNumber, searchQuery) => {
    const teacherClasses = await findAbsentByClassForAdmin(teacherClassId, selectedDate, pageNumber, itemsPerPge, searchQuery);
    setTeacherClasses(teacherClasses.data);
    setLoadingStudents(false);
    if (teacherClasses.data.length > 0) {
      setIsTeacherClasses(true);
      setPageCount(teacherClasses.pageCount);
    setCurrentPage(pageNumber + 1);
    } else {
      setIsTeacherClasses(false);
      setPageCount(teacherClasses.pageCount);
    }
  }

  const fetchData = async () => {
    try {
      await getAttendance(startDate, skip);
    } catch (error) {
      setLoadingStudents(false);
      setIsTeacherClasses(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchCategory = async (searchQuery) => {
    if (isChecked) {
      await getAttendance(startDate, skip, searchQuery);
      setCurrentStart(0);
    } else {
      await getAbsent(startDate, skip, searchQuery);
      setCurrentStart(0);
    }
  };

  const filterAttendanceByDate = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setStartDate(newDate)
    if (isChecked) {
      await getAttendance(newDate, skip, searchQuery);
      setCurrentStart(0);
    } else {
      await getAbsent(newDate, skip, searchQuery);
      setCurrentStart(0);
    }
    navigate(
      `/teacher/attendance-records/${teacherClassId}/${newDate}`
    );
  }

  const handleToggle = async (checkedStatus) => {
    if (checkedStatus) {
      await getAttendance(startDate, skip, searchQuery);
      setCurrentStart(0);
    } else {
      await getAbsent(startDate, skip, searchQuery);
      setCurrentStart(0);
    }
  }

  const handleChange = () => {
    const newCheckedStatus = !isChecked;
    setIsChecked(newCheckedStatus);
    handleToggle(newCheckedStatus);
  };

  const handlePagination = async (pageNumber) => {
    if (isChecked) {
      await getAttendance(startDate, pageNumber - 1, searchQuery);
    } else {
      await getAbsent(startDate, pageNumber - 1, searchQuery);
    }
  }
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        {/* Display Tabel */}
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="attendanceHeader">
                <Col className="wordingSection">
                  <h3 className="mb-0">Attendance</h3>
                  <label className="custom-toggle">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                    {isChecked ? <p className="present">
                      Present </p> : <p className="absent">Absent</p>}
                  </label>
                </Col>
                <div className="filterSection">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by full name"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => filterAttendanceByDate(date)}
                    className="dateSelector"
                    icon="fa fa-calendar"
                  />
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Registration Number</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Teacher</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Subject</th>
                </tr>
              </thead>
              <tbody>
                {!isTeacherClasses ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan={12}>
                      <div>
                        <img src={require("../../../assets/img/brand/nodata.png")} className="noDataImage" />
                      </div>
                    </td>
                  </tr>
                ) : teacherClasses?.map((teacherClass, index) => (
                  <tr key={index}>
                    <td>{teacherClass.registration_number}</td>
                    <td>{teacherClass.full_name}</td>
                    <td>{teacherClass.teacher}</td>
                    <td>{teacherClass.grade_name}</td>
                    <td>{teacherClass.subject_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default TeacherClassAttendanceSheet;
