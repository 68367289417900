import { useState, useEffect } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import TeacherHeader from "components/Headers/TeacherHeader";
import { getGraphData } from "actions/Dashboard";
import { getUser } from "actions/Auth";
import { getTeacherIncomeByMonth } from "actions/Dashboard";

const TeacherDashboard = () => {
  const [barGraphData, setBarGraphData] = useState(null);
  const [error, setError] = useState(null);
  const [incomeGraphData, setIncomeGraphData] = useState(null);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const fetchData = async () => {
    try {
      const user = await getUser();
      const startDate = new Date();
      const year = startDate.getFullYear();
      const incomeGraphData = await getTeacherIncomeByMonth(year, user.id);
      if (incomeGraphData.length <= 1) {
        let currentMonth = incomeGraphData.length === 1 ? incomeGraphData[0].month : new Date().toISOString().slice(0, 7);
        let date = new Date(currentMonth + "-01");
        date.setMonth(date.getMonth() - 1);
        let previousMonth = date.toISOString().slice(0, 7);
        incomeGraphData.unshift({ monthly_teacher_income: "0", month: previousMonth });
      }
      setIncomeGraphData(incomeGraphData)
      const barGraphData = await getGraphData(5, user.id);
      setBarGraphData(barGraphData);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!Array.isArray(incomeGraphData)) {
    return <Container fluid>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Spinner className="m-10">Loading...</Spinner>
      </Row>
    </Container>;
  }
  const dataValues = incomeGraphData?.map(item => parseInt(item.monthly_teacher_income, 10));
  const labels = incomeGraphData?.map(item => {
    return (item.month);
  });

  const lineGraphData = {
    labels: labels,
    datasets: [{
      label: 'Income',
      data: dataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const barDataValues = barGraphData?.map(item => parseInt(item.count, 10));

  const barLabels = barGraphData?.map(item => {
    return (item.month);
  });

  const numericBarDataValues = barDataValues?.map(value => parseInt(value, 10));
  const barData = {
    labels: barLabels,
    datasets: [{
      label: 'Count',
      data: numericBarDataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };
  return (
    <>
      <TeacherHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-white mb-0">Monthly Income</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={lineGraphData}
                    options={{
                      responsive: true,
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 2000,
                          }
                        }]
                      }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Paid Student Count</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={barData}
                    options={labels}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TeacherDashboard;
