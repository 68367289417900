import TeacherClassDetails from "views/pages/Teacher/TeacherClassDetails";
import TeacherClassAttendanceSheet from "views/pages/Teacher/TeacherClassAttendanceSheet";
import TeacherClassStudentList from "views/pages/Teacher/TeacherClassStudentList";
import TeacherDailyPaymentRecords from "views/pages/Teacher/TeacherDailyPayments";
import TeacherIncomeDetails from "views/pages/Teacher/TeacherIncome";
import TeacherPayments from "views/pages/Teacher/TeacherPayments";
import TeacherProfile from "views/pages/TeacherProfile";
import TeacherDashboard from "views/teacherDashboard";
import TeacherDailyReport from "views/pages/Teacher/TeacherDailyReport";
import TeacherStudentPayCancel from "views/pages/Teacher/TeacherStudentPayCancel";
import Tutes from "views/pages/Teacher/Tutes";
import TeacherClassFreeCard from "views/pages/Teacher/TeacherClassFreeCard";
import TeacherClassHalfCard from "views/pages/Teacher/TeacherClassHalfCard";

var teacherRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-leeds",
    component: <TeacherDashboard />,
    layout: "/teacher",
  },
  {
    path: "/classdetails",
    name: "Class Details",
    icon: "ni fa fa-cubes text-leeds",
    component: <TeacherClassDetails />,
    layout: "/teacher",
  },  
  {
    path: "/income",
    name: "Income Details",
    icon: "ni fa fa-usd text-leeds",
    component: <TeacherIncomeDetails />,
    layout: "/teacher",
  },  
  {
    path: "/report",
    name: "Report",
    icon: "ni fa-solid fa-file-invoice text-leeds",
    component: <TeacherDailyReport />,
    layout: "/teacher",
  },  
  {
    path: "/canceled",
    name: "Cancelled Payments",
    icon: "ni fa-solid fa-ban text-leeds",
    component: <TeacherStudentPayCancel />,
    layout: "/teacher",
  },  
  {
    path: "/teacher-profile",
    name: "Teacher Profile",
    icon: "ni ni-single-02 text-leeds",
    component: <TeacherProfile />,
    layout: "/teacher",
  },
  {
    path: "/payment-records/:teacherClassId/:month",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherPayments />,
    layout: "/teacher",
  },
  {
    path: "/daily-payment-records/:teacherClassId/:date",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherDailyPaymentRecords />,
    layout: "/teacher",
  },
  {
    path: "/classStudents/:teacherClassId",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherClassStudentList />,
    layout: "/teacher",
  },
  {
    path: "/attendance-records/:teacherClassId/:date",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherClassAttendanceSheet />,
    layout: "/teacher",
  },
  {
    path: "/tutes/:teacherClassId",
    name: "Class Tutes",
    icon: "ni ni-bus-front-12 text-orange",
    component: <Tutes />,
    layout: "/teacher",
  },
  {
    path: "/free-cards/:teacherId/:gradeId/:subjectId",
    name: "Free Cards",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherClassFreeCard />,
    layout: "/teacher",
  },
  {
    path: "/half-free-cards/:teacherId/:gradeId/:subjectId",
    name: "Half Free Cards",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherClassHalfCard />,
    layout: "/teacher",
  },
];
export default teacherRoutes;
