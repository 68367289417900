import {
    Card,
    CardHeader,
    Row,
    CardBody,
} from "reactstrap";
import React from 'react';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/new-leeds-white-logo.png';
import LogoImage from '../../assets/img/brand/LOGO_ID.png';
import QRCode from 'react-qr-code';

const StudentIdCard = ({ student }) => {
    return (
        <div className="studentIdSec">
            <Card
                className="qRCard"
                style={{
                    position: 'relative',
                    margin: 'auto',
                    color: 'white',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '20px',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '130px',
                        // backgroundColor: '#103A56',
                        zIndex: 0,
                    }}
                    className="shape"
                ></div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '10px',
                        backgroundColor: '#103A56',
                        zIndex: 0,
                    }}
                ></div>
                <CardHeader
                    className="idCardHeader"
                    style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10, paddingTop: '10px' }}
                >
                    <img src={LogoImage} alt="Institute Logo" width={150} crossOrigin="anonymous" />
                </CardHeader>
                <CardBody className="qrBody" style={{ zIndex: 1, position: 'relative' }}>
                    {/* <div style={{backgroundColor: 'transparent'}}>
                <img src={LogoImage} width={150} borderRadius={5} crossOrigin="Access-Control-Allow-Origin" />
                </div> */}
                    <p className="regNumber">
                       Index: {student.index}
                    </p>
                    <Row className="studentNameSection">
                        <p className="title">
                            {student.full_name.toUpperCase()}
                        </p>
                    </Row>
                </CardBody>
                <Barcode
                    className="barCodeId"
                    value={student.barcode}
                    height={50}
                    fontSize={14}
                    style={{ position: 'relative', zIndex: 1 }}
                />
            </Card>
            <Card
                className="qRCardBack"
                style={{
                    position: 'relative',
                    margin: 'auto',
                    color: 'white',
                    borderRadius: '20px',
                    textAlign: 'center',
                    padding: '20px',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '120px',
                        zIndex: 0,
                    }}
                    className="shape"
                ></div>
                <CardHeader
                    className="idCardHeader"
                    style={{ borderBottom: 'none', backgroundColor: 'transparent', position: 'relative', zIndex: 10 }}
                >
                    <img src={Logo} alt="Institute Logo" width={100} crossOrigin="anonymous" />
                </CardHeader>
                <CardBody className="qrBackBody" style={{ zIndex: 1, position: 'relative' }}>
                    <div className="qrDiv">
                        <QRCode value={student.barcode} size={90} className="code" />
                    </div>
                    <p className="termsTitle">Terms & Conditions</p>
                    <p className="terms">This card is non-transferable and the property of New LEEDS Academy, Badulla.
                        It is the cardholder's responsibility to protect and maintain the condition of his/her card.
                    </p>
                </CardBody>
                <div className="instDetails">
                    <p style={{ marginBottom: '5px', fontSize: '8px' }}>If found please return to:</p>
                    <p>New LEEDS Academy</p>
                    <p>76, Hunukotuwa Road</p>
                    <p>Badulla</p>
                    <p>0728676766</p>
                </div>
            </Card>
        </div>
    );
};

export default StudentIdCard;
