import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { createTeacher } from "actions/Teachers";
import { editTeacher } from "actions/Teachers";
import { editUserTeacherPassword } from "actions/Teachers";
import { Link } from "react-router-dom";
import { createTeacherClass } from "actions/Teachers";
import { getAllActiveGrades } from "actions/Grades";
import Paginations from "components/Pagination/Paginations";
import { getTeachersBySearch } from "actions/Teachers";
import { getTeachersByLimit } from "actions/Teachers";
import { BiEdit } from "react-icons/bi";
import { FaExpeditedssl } from "react-icons/fa";
import { getAllTeachers } from "actions/Teachers";
import "../../assets/css/styles.css";
import { getAllActiveSubjects } from "actions/Subjects";

const Teachers = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const [allTeachers, setAllTeachers] = useState(null);
  const [allActiveTeachers, setAllActiveTeachers] = useState(null);
  const [isAllTeachers, setIsAllTeachers] = useState(false);
  const [allSubjets, setAllSubjects] = useState(null);
  const [allGrades, setAllGrades] = useState(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [editPasswordModal, setEditPasswordmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingTeachers, setLoadingTeachers] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [selectedTeacherForClass, setSelectedTeacherForClass] = useState(null);
  const [selectedSubjectForClass, setSelectedSubjectForClass] = useState(null);
  const [selectedGradeForClass, setSelectedGradeForClass] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPge = 10;
  const skip = 0;
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [isAddClassButtonDisabled, setAddClassButtonDisabled] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [initialTeacher, setInitialTeacher] = useState({
    id: null,
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
    status: "",
  });
  const [isData, setIsData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
  });

  const [teacherClass, setTeacherClass] = useState({
    teacherId: null,
    gradeId: null,
    subjectId: null,
    price: null,
    class_year: null,
    teacher_percentage: null,
    paymentType: null,
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    password: "",
  });

  const [isTeacherClassError, setTeacherClassError] = useState({
    teacherId: "",
    gradeId: "",
    subjectId: "",
    price: "",
    class_year: "",
    teacher_percentage: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
    password: "",
  });

  const [editPassword, setEditPassword] = useState({
    teacherId: null,
    newPassword: "",
    confirmPassword: "",
  });

  const [isEditPasswordError, setEditPasswordError] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [editorData, setEditorData] = useState("");

  const getTeachers = async (itemsPerPge, skip) => {
    const teachers = await getTeachersByLimit(itemsPerPge, skip);
    setPageCount(teachers.data.pageCount);
    setAllTeachers(teachers.data.data);
    setLoadingTeachers(false);
    const teacherData = teachers.data.data;
    if (teacherData.length > 0) {
      setIsAllTeachers(true);
    }
  }

  const fetchData = async () => {
    try {
      await getTeachers(itemsPerPge, skip);
      const allActiveTeachers = await getAllTeachers();
      setAllActiveTeachers(allActiveTeachers)
      const subjects = await getAllActiveSubjects();
      setAllSubjects(subjects);
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
    } catch (error) {
      setLoadingTeachers(false);
      setIsAllTeachers(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchCategory = async (searchQuery) => {
    const searched = await getTeachersBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllTeachers(searched.data.data);
  };

  const filterBySearch = async (itemsPerPge, skip, searchQuery) => {
    const searched = await getTeachersBySearch(itemsPerPge, skip, searchQuery);
    setPageCount(searched.data.pageCount);
    setAllTeachers(searched.data.data);
    setCurrentPage(skip + 1);
  }

  const handlePagination = async (pageNumber) => {
    if (searchQuery !== " ") {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery);
    } else {
      await fetchData(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }


  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  // Validate input
  const isValidTeacher = (value) => {
    const errors = {};
    if (value.full_name.length < 3) {
      errors.full_name =
        "Teacher Full Name must be at least 3 characters long.";
    }
    if (!isEmail(value.email)) {
      errors.email = "Value must be a valid email.";
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false; 
    } else {
      return true;
    }
  };

  const cleanData = (data) => {
    const cleanedData = { ...data };

    Object.keys(cleanedData).forEach((key) => {
      if (cleanedData[key] === "") {
        delete cleanedData[key];
      }
    });

    return cleanedData;
  };

  const insertNewTeacher = async () => {
    if (isValidTeacher(isData)) {
      setinsertButtonDisabled(true);
      const cleanedIsData = cleanData(isData);
      const response = await createTeacher(cleanedIsData, editorData);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        setIsData({
          ...isData,
          full_name: "",
          email: "",
          phone_number: "",
          password: "",
        });
        fetchData();
        setinsertButtonDisabled(false);
      } else {
        setErrorMessage(response.message);
        setinsertButtonDisabled(false);
        setFailed(true);
      }
    }
  };

  const handleTeacherClassInputChange = (event) => {
    setTeacherClassError({ ...isTeacherClassError, [event.target.name]: "" });
    setTeacherClass({ ...teacherClass, [event.target.name]: event.target.value });
  };
  const isValidTeacherClass = (value) => {
    const errors = {};
    if (value.price.length < 3) {
      errors.price =
        "Price must be at least 3 characters long.";
    }
    if (value.class_year.length < 1) {
      errors.class_year = "Class must be at least 5 characters long.";
    }
    if (Object.keys(errors).length > 0) {
      setTeacherClassError(errors);
      return false; 
    } else {
      return true;
    }
  };

  const insertNewTeacherClass = async () => {
    if (isValidTeacherClass(teacherClass)) {
      setAddClassButtonDisabled(true);
      const teacherClassNumbers = {
        ...teacherClass,
        teacherId: Number(teacherClass.teacherId),
        gradeId: Number(teacherClass.gradeId),
        subjectId: Number(teacherClass.subjectId),
        price: Number(teacherClass.price),
        teacher_percentage: Number(teacherClass.teacher_percentage),
        year: Number(teacherClass.class_year),
        paymentType:'MONTHLY',
      };
      const response = await createTeacherClass(teacherClassNumbers);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        setTeacherClass({
          ...teacherClass,
          teacherId: "",
          gradeId: "",
          subjectId: "",
          price: "",
          class_year: "",
          teacher_percentage: "",
          paymentType: "",
        });
        setSelectedTeacherForClass(null);
        setSelectedSubjectForClass(null);
        setSelectedGradeForClass(null);
        setAddClassButtonDisabled(false);
        fetchData();
      } else {
        setErrorMessage(response.message);
        setFailed(true);
        setAddClassButtonDisabled(false);
      }
    }
  }

  const handleEditClick = (teacher) => {
    setSelectedTeacher(teacher);
    setInitialTeacher(teacher);
    setEditmodal(true);
  };

  const handleEditPasswordClick = (teacher) => {
    setSelectedTeacher(teacher);
    setEditPassword({
      ...editPassword,
      teacherId: teacher.id,
    });
    setEditPasswordmodal(true);
  };

  const isValidEditTeacher = (value) => {
    const errors = {};
    if (value.full_name.length < 6) {
      errors.full_name =
        "Teacher Full Name must be at least 6 characters long.";
    }
    if (!isEmail(value.email)) {
      errors.email = "Value must be a valid email.";
    }
    if (!isMobilePhone(value.phone_number)) {
      errors.phone_number = "Value must be a valid phone number.";
    }
    if (Object.keys(errors).length > 0) {
      setEditError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedTeacher) {
    const changedValues = {};
    for (const key in selectedTeacher) {
      if (
        key !== "id" &&
        selectedTeacher.hasOwnProperty(key) &&
        selectedTeacher[key] !== initialTeacher[key]
      ) {
        changedValues[key] = selectedTeacher[key];
      }

      if (key === "id") {
        changedValues[key] = selectedTeacher[key];
      }
    }
    return changedValues;
  }

  const editTeacherDetails = async () => {
    const validityCheck = isValidEditTeacher(selectedTeacher);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedTeacher);
      const body = JSON.stringify(data);
      const response = await editTeacher(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        await fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  const isValidPassword = (value) => {
    const errors = {};
    if (value.newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    }
    if (value.newPassword !== value.confirmPassword) {
      errors.confirmPassword = "Password and Confirm Password must be matched.";
    }
    if (Object.keys(errors).length > 0) {
      setEditPasswordError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };
  const editTeacherPassword = async () => {
    if (isValidPassword(editPassword)) {
      const response = await editUserTeacherPassword(editPassword);
      if (response.success === true) {
        setEditPasswordmodal(false);
        setSuccessMessage(response.message);
        setSuccess(true);
        fetchData();
      } else {
        setErrorMessage(response.message);
        setFailed(true);
      }
    }
  };
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Row>
          <Col lg="6" sm="12">
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Add New Teacher</h3>
                  </Col>
                </Row>
              </CardHeader>
              {/* Insert Form */}
              <Form className="ml-4 mb-4 mr-4">
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        id="fullName"
                        placeholder="Full Name"
                        type="text"
                        value={isData.full_name}
                        onChange={handleInputChange}
                        name="full_name"
                      />
                      {isError.full_name && (
                        <p className="text-danger">{isError.full_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="email"
                        placeholder="Email Address"
                        type="test"
                        value={isData.email}
                        onChange={handleInputChange}
                        name="email"
                      />
                      {isError.email && (
                        <p className="text-danger">{isError.email}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="phone_number"
                        placeholder="Phone Number"
                        type="text"
                        value={isData.phone_number}
                        onChange={handleInputChange}
                        name="phone_number"
                      />
                      {isError.phone_number && (
                        <p className="text-danger">{isError.phone_number}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="password"
                        placeholder="Password"
                        type="password"
                        value={isData.password}
                        onChange={handleInputChange}
                        name="password"
                      />
                      {isError.password && (
                        <p className="text-danger">{isError.password}</p>
                      )}
                    </FormGroup>
                    <Button
                      color="primary"
                      type="button"
                      onClick={insertNewTeacher}
                      disabled={
                        !isData.full_name ||
                        isInsertButtonDisabled
                      }
                    >
                      Insert
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            <Card className="mb-4 shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Add New Class</h3>
                  </Col>
                </Row>
              </CardHeader>
              {/* Insert Form */}
              <Form className="ml-4 mb-4 mr-4">
                <Row>
                  <Col>
                    <FormGroup>
                      <Dropdown
                        isOpen={teacherDropdownOpen}
                        toggle={() =>
                          setTeacherDropdownOpen(!teacherDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedTeacherForClass ? selectedTeacherForClass.full_name : "Select a Teacher"}
                        </DropdownToggle>
                        {allActiveTeachers && allActiveTeachers.length > 0 ? (
                          <DropdownMenu className="dropdownMenuStyle">
                            {allActiveTeachers
                              .filter(teacher => teacher.status === "ACTIVE")
                              .sort((a, b) => a.full_name.localeCompare(b.full_name))
                              .map((teacher) => (
                                <DropdownItem
                                  key={teacher.full_name}
                                  value={teacher.id}
                                  onClick={() => [
                                    setTeacherClass({
                                      ...teacherClass, teacherId: teacher.id
                                    }),
                                    setSelectedTeacherForClass(teacher),
                                  ]}
                                >
                                  {teacher.full_name}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        ) : null}

                      </Dropdown>
                    </FormGroup>
                    <Row>
                      <Col xl="7" md="12">
                        <FormGroup>
                          <Dropdown
                            isOpen={subjectDropdownOpen}
                            toggle={() =>
                              setSubjectDropdownOpen(!subjectDropdownOpen)
                            }
                          >
                            <DropdownToggle caret>
                              {selectedSubjectForClass ? selectedSubjectForClass.name : "Select a Subject"}
                            </DropdownToggle>
                            {allSubjets && allSubjets.length > 0 ? (
                              <DropdownMenu className="dropdownMenuStyle">
                                {allSubjets && allSubjets
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((subject) => (
                                  <DropdownItem
                                    key={subject.name}
                                    value={subject.id}

                                    onClick={() => [
                                      setTeacherClass({
                                        ...teacherClass, subjectId: subject.id
                                      }),
                                      setSelectedSubjectForClass(subject),
                                    ]}
                                  >
                                    {subject.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            ) : null}
                          </Dropdown>
                        </FormGroup>
                      </Col>
                      <Col xl="5" md="12">
                        <FormGroup>
                          <Dropdown
                            isOpen={gradeDropdownOpen}
                            toggle={() =>
                              setGradeDropdownOpen(!gradeDropdownOpen)
                            }
                          >
                            <DropdownToggle caret>
                              {selectedGradeForClass ? selectedGradeForClass.name : "Select a Grade"}
                            </DropdownToggle>
                            {allGrades && allGrades.length > 0 ? (
                              <DropdownMenu className="dropdownMenuStyle">
                                {allGrades && allGrades.map((grade) => (
                                  <DropdownItem
                                    key={grade.name}
                                    value={grade.id}
                                    onClick={() => [
                                      setTeacherClass({
                                        ...teacherClass, gradeId: grade.id
                                      }),
                                      setSelectedGradeForClass(grade),
                                    ]}
                                  >
                                    {grade.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            ) : null}
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            id="price"
                            placeholder="Class Fee"
                            type="number"
                            value={teacherClass.price || ''}
                            onChange={handleTeacherClassInputChange}
                            name="price"
                          />
                          {isTeacherClassError.price && (
                            <p className="text-danger">{isTeacherClassError.price}</p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            id="teacher_percentage"
                            placeholder="Payment Percentage"
                            type="number"
                            min="0"
                            max="100"
                            value={teacherClass.teacher_percentage || ''}
                            onChange={handleTeacherClassInputChange}
                            name="teacher_percentage"
                          />
                          {isTeacherClassError.teacher_percentage && (
                            <p className="text-danger">{isTeacherClassError.teacher_percentage}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col md="6">
                    <FormGroup>
                    <Dropdown
                      isOpen={paymentTypeDropdownOpen}
                      toggle={() => setPaymentTypeDropdownOpen(!paymentTypeDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedtPaymentType
                          ? selectedtPaymentType
                          : "Payment Type"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(PaymentType).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => [
                              setTeacherClass({
                                ...teacherClass, paymentType: value
                              }),
                              setSelectedPaymentType(value)
                            ]}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    </FormGroup>
                      </Col> */}
                    <Col md="12">
                    <FormGroup>
                      <Input
                        id="class_year"
                        placeholder="Class Year"
                        type="number"
                        value={teacherClass.class_year}
                        onChange={handleTeacherClassInputChange}
                        name="class_year"
                      />
                      {isTeacherClassError.class_year && (
                        <p className="text-danger">{isTeacherClassError.class_year}</p>
                      )}
                    </FormGroup>
                    </Col>
                    </Row>
                    <Button
                      color="primary"
                      type="button"
                      onClick={insertNewTeacherClass}
                      disabled={
                        !teacherClass.price ||
                        !teacherClass.class_year ||
                        !teacherClass.teacherId ||
                        !teacherClass.gradeId ||
                        !teacherClass.subjectId ||
                        isAddClassButtonDisabled
                      }
                    >
                      Add New Class
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card></Col>
        </Row>
        {/* Display Tabel */}
        {isLoadingTeachers ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllTeachers ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0">Teachers</h3>
                </Col>
                <Col className="justify-content-end" md="8" lg="4">
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      placeholder="Search by full name"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                        setCurrentStart(0);
                        searchCategory(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allTeachers?.map((teacher, index) => (
                  <tr key={index}>
                    <td>{teacher.full_name}</td>
                    <td>{teacher.phone_number}</td>
                    <td>{teacher.status}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/teachers/${teacher.id}`}
                      // onClick={() => handleEditClick(teacher)}
                      >
                        Class Details
                      </Button>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/teachers/payments/${teacher.id}/${teacher.full_name}`}
                      // onClick={() => handleEditClick(teacher)}
                      >
                        Payments
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editTeacher"
                        onClick={() => handleEditClick(teacher)}
                      >
                        <BiEdit />
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editPassword"
                        onClick={() => handleEditPasswordClick(teacher)}
                      >
                        <FaExpeditedssl />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        {/* Edit teacher */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Teacher
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setEditError({
                  full_name: "",
                  phone_number: "",
                  password: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      id="editFullName"
                      placeholder="Full Name"
                      type="text"
                      value={
                        selectedTeacher?.full_name
                          ? selectedTeacher?.full_name
                          : ""
                      }
                      name="full_name"
                      onChange={(e) => {
                        setSelectedTeacher({
                          ...selectedTeacher,
                          full_name: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ full_name: "" });
                      }}
                    />
                    {isEditError.full_name && (
                      <p className="text-danger">{isEditError.full_name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      value={selectedTeacher?.email ? selectedTeacher.email : ""}
                      readOnly />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="editPhoneNumber"
                      placeholder="Phone Number"
                      type="text"
                      value={
                        selectedTeacher?.phone_number
                          ? selectedTeacher?.phone_number
                          : ""
                      }
                      name="phone_number" // Added name attribute
                      onChange={(e) => {
                        setSelectedTeacher({
                          ...selectedTeacher,
                          phone_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ phone_number: "" });
                      }}
                    />
                    {isEditError.phone_number && (
                      <p className="text-danger">{isEditError.phone_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedTeacher
                          ? selectedTeacher.status
                          : "Select a Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Status).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => [
                              setSelectedTeacher({
                                ...selectedTeacher,
                                status: value,
                              }),
                              setEditedInput(true),
                              setDisabledEditButton(false),
                            ]}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editTeacherDetails()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={editPasswordModal}
          toggle={() => [setEditPasswordmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Password
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditPasswordmodal(false),
                setEditPasswordError({
                  newPassword: "",
                  confirmPassword: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      id="editNewPassword"
                      placeholder="New Password"
                      type="password"
                      name="newPassword"
                      onChange={(e) => {
                        setEditPassword({
                          ...editPassword,
                          newPassword: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditPasswordError({ newPassword: "" });
                      }}
                    />
                    {isEditPasswordError.newPassword && (
                      <p className="text-danger">
                        {isEditPasswordError.newPassword}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="editConfirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword" // Added name attribute
                      onChange={(e) => {
                        setEditPassword({
                          ...editPassword,
                          confirmPassword: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditPasswordError({ confirmPassword: "" });
                      }}
                    />
                    {isEditPasswordError.confirmPassword && (
                      <p className="text-danger">
                        {isEditPasswordError.confirmPassword}
                      </p>
                    )}
                  </FormGroup>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editTeacherPassword()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Teachers;
