import axios from "axios";
const apiUrl = "https://new-leeds-academy.ardillalabs.cloud/api"; 

export const getStudentByClassAssistants = async (barcode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/users/get-student/${barcode}`,
          config
        );
        
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

export const updateStudentByClassAssistant = async (editFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.put(
          `${apiUrl}/users/update-student-info`,
          editFormData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
          reject({
            response: {
              data: {
                status: err.response.data.statusCode,
                statusText: err.code,
              },
            },
          });
      }
    });
  };

  export const getLastThreeMonthsFeeByClassAssistants = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const body = JSON.stringify(formData);

        const response = await axios.post(
          `${apiUrl}/class-user/last-three-months-class-fee`,
          body,
          config
        );
        
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const updateStudentPaymentByClassAssistant = async (editFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.put(
          `${apiUrl}/class-fee/update-class-fee`,
          editFormData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const getTeacherPaymentsByClassAssistants = async (teacherId, dateOrMonth) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const response = await axios.get(
          `${apiUrl}/teacher-class/filter-by-teacher-id-and-date/${teacherId}/${dateOrMonth}`,
          config
        );
        
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const createStudentByClassAssistant = async (barcode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const body = JSON.stringify(barcode);
        const response = await axios.post(
          `${apiUrl}/users/create-student`,
          body,
          config
        );

        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  }

  export const getActiveStudentsByClassAssistants = async (grade, skip, limit) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const response = await axios.get(
          `${apiUrl}/users/get-ids?skip=${skip}&limit=${limit}&role=student&grade=${grade}`,
          config
        );
        
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const filterStudentListByClassAssistant = async (limit, skip, search, gradeId, subjectId, teacherId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        let url = `${apiUrl}/class-user?limit=${limit}&skip=${skip}&status=true&teacherId=${teacherId}&subjectId=${subjectId}&gradeId=${gradeId}&search=${search}`;
  
        const response = await axios.get(url, config);
  
        if (response.status === 200) {
          resolve(response);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  }
  
  export const updateStudentPaymentTypeByClassAssistant = async (editFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.put(
          `${apiUrl}/class-user/update-class-user-info`,
          editFormData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const getInstituteReportByClassAssistants = async (dateOrMonth) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const response = await axios.get(
          `${apiUrl}/class-fee/institute-report/${dateOrMonth}`,
          config
        );

        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const getStudentByClassAssistantById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.get(
          `${apiUrl}/users/get-user-by-registration-number/${id}`,
          config
        );
        
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };

  export const filterStudentListByAssistant = async (search) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        let url = `${apiUrl}/users/active-student?search=${search}`;
  
        const response = await axios.get(url, config);
  
        if (response.status === 200) {
          resolve(response);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  }

  export const editProfileImage = async (image, studentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        };
        const formData = new FormData();
  
        formData.append("id", studentId);
        formData.append("image", image.image);
  
        const response = await axios.put(
          `${apiUrl}/users/update-student-dp`,
          formData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.data.message,
                status: err.code,
                success: false,
              },
            },
          });
        }
      }
    });
  };

  export const updateStudentBulkPaymentByClassAssistant = async (editFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
  
        const response = await axios.post(
          `${apiUrl}/class-fee/bulk-update-class-fee`,
          editFormData,
          config
        );
  
        if (response.data.success === true) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  }

  export const getAttendanceCountByClassAssistants = async (id, month) => {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };

        const response = await axios.get(
          `${apiUrl}/attendance/find-monthly-attendance/${id}/${month}`,
          config
        );
        
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      } catch (err) {
        if (err.message === "Network Error") {
          reject({
            response: {
              data: {
                message: "Network Error",
                status: err.code,
                statusText: err.code,
              },
            },
          });
        } else {
          reject({
            response: {
              data: {
                message: err.response.message,
                status: err.code,
                statusText: err.code,
              },
            },
          });
        }
      }
    });
  };